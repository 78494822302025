<template>
  <module
      ref="module"
      id="allstrategies"
      titleIcon="nc-icon nc-sound-wave"
      :title="$t('allstrategies')"
      :use-default-list="false"
    >
    <template slot="toolbar-global">
      <fg-input class="input-sm"
                    :placeholder="searchuserTxt"
                    v-model="searchuser"
                    addon-right-icon="nc-icon nc-zoom-split"
                    :disabled="!(inprogress == false && clonedStrategies.length > 0)"
                    v-on:keyup="searchusers">
          </fg-input>
    </template>
    <div slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <!-- No Accounts -->
      <div v-if="clonedStrategies.length == 0 && inprogress == false" class="text-center">
        <h2>{{ $t('nostrategies') }}</h2>
      </div>

      <div class="row" v-if="inprogress == false && strategies.length > 0">
        <div class="col-lg-3 col-md-6 col-sm-6" v-for="acc in strategies">

          <div class="card">
            <div class="card-header text-left">
              <div class="float-right status-icon" style="position: relative;top: 5px;">

                <div :title="`${$t('platformtxt')}`">
                  <i class="fa fa-server"></i>
  <!--                {{ acc.platform.toUpperCase() }}-->
                </div>
                <div v-if="acc.discoverable == 1">
                  <i class="fa fa-money"></i>&nbsp;&nbsp;{{ acc.price * 100 }}%
                </div>
                <div>
                  <i class="fa fa-users"></i>&nbsp;&nbsp;{{ acc.followers }}x
                </div>
                <div>
                  {{ acc.funds }}
                </div>
              </div>

              <h5 class="card-title">
                {{ acc.strat_name }}
              </h5>
              <p class="category">
                {{ acc.first_name }} {{ acc.last_name }}
              </p>
            </div>

            <hr style="margin: 10px 0px 0px 0px;" />

            <div slot="footer" class="card-footer text-center">

              <p-button type="primary" icon round slot="footer"
                        v-on:click="openStrategy(`${acc.userid}`, `${acc.strat_id}`, `${acc.acc_id}`)">
                <el-tooltip :content="strategyinfo" :open-delay="100" popper-class="adjust-width" placement="top">
                  <i class="fa fa-cogs"></i>
                </el-tooltip>
              </p-button>
              &nbsp;
              <p-button type="warning" icon round slot="footer" v-on:click="publicOpen(`${acc.strat_id}`)"
                        :disabled="acc.discoverable == 0">
                <el-tooltip :content="linkpublic" :open-delay="100" popper-class="adjust-width" placement="top">
                  <i class="fa fa-share-alt"></i>
                </el-tooltip>
              </p-button>
              &nbsp;
              <p-button type="success" icon round slot="footer" v-on:click="stratInfo(`${acc.strat_id}`)">
                <el-tooltip :content="ordercommission" :open-delay="100" popper-class="adjust-width" placement="top">
                  <i class="fa fa-line-chart"></i>
                </el-tooltip>
              </p-button>
            </div>

          </div>

        </div>
      </div>
    </div>
  </module>
</template>
<script>
  import Vue from 'vue'
  import {Card} from 'src/components/UIComponents'
  import {Table, TableColumn} from 'element-ui'
  import Constants from "@/assets/constants";
  import {Tooltip} from 'element-ui';
  Vue.use(Table)
  Vue.use(TableColumn)
  const rules = Constants.methods.getRules();
  const user_data = JSON.parse(localStorage.getItem('user-info'));

  export default {
    components: {
      Card,
      [Tooltip.name]: Tooltip
    },
    data () {
      return {
        inprogress: true,
        strategies: [],
        clonedStrategies: [],
        searchuser: null,
        searchuserTxt: this.$t('searchuser'),
        inprogresstxt: this.$t('loadingmarketstrategies'),
        strategyinfo: this.$t('strategyinfo'),
        ordercommission: this.$t('ordercommission'),
        linkpublic: this.$t('pricing_public'),
      }
    },
    methods: {
      loadedStratDetail(resp) {
        if (!resp.success) {
          return ;
        }
        for(var i=0;i<resp.data.length;i++) {
          let s = resp.data[i];
          let fidx = this.strategies.findIndex(st => st.strat_id == s.strat_id);
          if (fidx >= 0) {
            this.strategies[fidx].funds = s.underManagementTxt;
            this.strategies[fidx].strat_url = s.strat_url;
            this.strategies[fidx].strat_desc = s.strat_desc;
            this.strategies[fidx].price = s.price;
          }
        }
        this.clonedStrategies = this.strategies.slice(0);
        this.inprogress = false;
      },
      loadStrategies(resp) {
        //console.log(resp);
        this.strategies = resp.data;
        this.$getStrategyDetail(null)
          .then(this.loadedStratDetail, this.failop);
      },
      openStrategy(userid, sid, aid) {
        location.href = "/admin_strategies/"+ userid +"/"+ aid +"/"+ sid;
      },
      stratInfo(sid) {
        const sidx = this.strategies.findIndex(s => s.strat_id == sid);
        if (sidx >= 0) {
          const _s = this.strategies[sidx];
          localStorage.setItem('_tmp-sid-account', JSON.stringify({
            sid: sid,
            sname: _s.strat_name,
            sdesc: _s.strat_desc,
            account: _s.account,
            price: _s.price,
            discoverable: _s.discoverable
          }));
          window.open("/admin_strategies/info", "_blank");
        }
      },
      publicOpen(sid) {
        const strat = this.strategies.find(s => s.strat_id == sid);
        window.open(rules.getPublicUrl(strat.strat_url), "_blanks");
      },
      failop (error) {
        console.log(error);
      },
      searchusers() {
        if (this.inprogress == false) {
          this.strategies = [];

          let allstrategies = this.clonedStrategies.slice(0);
          let searchu = null;
          if (this.searchuser !== null && this.searchuser.length > 0) {
            searchu = new RegExp(this.searchuser, 'gi');
          }

          for(var i=0;i < allstrategies.length;i++) {
            let addto = true;
            let fullname = allstrategies[i]['strat_name'] +" "+ allstrategies[i]['first_name'] +" "+ allstrategies[i]['last_name'] +" "+ allstrategies[i]["userid"];
            //console.log(fullname);

            if (searchu !== null && fullname.match(searchu) === null) {
              addto = false
            }
            // Add to the
            if (addto) {
              this.strategies.push(allstrategies[i]);
            }
          }
        }
      }
    },
    mounted () {
      this.$getStrategiesOverview().then(this.loadStrategies, this.failop);
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    }
  }
</script>
<style lang="scss">
  .card-title {
    margin-bottom: 0.5rem;
  }
  .count {
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
    display: inline-block;
    font-weight: bold;
    height: 40px;
    line-height: 35px;
    text-align: center;
    min-width: 40px;
    padding: 0 10px;
    font-size: 11px;
  }
  .follower-count {
    color: #51bcda;
    border: 2px solid #51bcda;
  }
  .follower-bubble {
    position: relative;
    left: 13px;
  }
  .follower-bubble-more {
    position: relative;
    left: 20px;
  }
</style>
